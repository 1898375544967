@import "vars";
@import "ui-kit--buttons";
@import "ui-kit--forms";
@import "ui-kit--typography";

$font-path: '../../fonts/';
@include font-face("Mulish", "#{$font-path}mulish/Mulish-Regular", 400, inherit, eot woff2 woff ttf otf svg);
@include font-face("Mulish", "#{$font-path}mulish/Mulish-Bold",    600, inherit, eot woff2 woff ttf otf svg);
@include font-face("Mulish", "#{$font-path}mulish/Mulish-Medium",  500, inherit, eot woff2 woff ttf otf svg);

@include font-face("NotoSans", "#{$font-path}noto-sans/NotoSans-Regular",      400, inherit, eot woff2 woff ttf otf svg);
@include font-face("NotoSans", "#{$font-path}noto-sans/NotoSans-Light",        300, inherit, eot woff2 woff ttf otf svg);
@include font-face("NotoSans", "#{$font-path}noto-sans/NotoSans-SemiBold", 500, inherit, eot woff2 woff ttf otf svg);
@include font-face("NotoSans", "#{$font-path}noto-sans/NotoSans-Bold",     600, inherit, eot woff2 woff ttf otf svg);

html, body{
  min-height: 100vh;
  min-height: -webkit-fill-available;
}

body{
  font-family: $mainFont;
  font-size: $main-text;
  line-height: 1.5;
  color: $color-dark;
  overflow-x: hidden;
}

.container{
  width: 100%;
  max-width: 100%;
  padding-left: 20px;
  padding-right: 20px;
}

img {
  height: auto;
  max-width: 100%;
}

* {
  box-sizing: border-box;
}

ul, ol{
  margin: 0;
  padding: 0;
  list-style: none;
}

.d-none,
.hidden{
  display: none;
}

.nowrap{
  white-space: nowrap;
}

.chrome-picker{
  #rc-editable-input-1{
    line-height: 22px;
    height: 22px;
  }
}