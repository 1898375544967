.input {
  &:focus {
    outline: none;
    -webkit-appearance: none;
  }
}

.UserSelectList .fieldset__block-title,
label,
.label{
  color: $color-dark;
  margin-bottom: 5px;
  display: inline-block;
  width: auto;
  font-size: $main-text;
}

.input-group-text,
input,
textarea,
select{
  border: 1px solid #d7dfef;
  background-color: #fff;
  width: 100%;
  color: $color-dark;
  font-weight: 400;
  padding: 0 18px 0 18px;
  font-size: $input-fz;
  outline: none;
  border-radius: 5px;
  font-family: $mainFont;

  &:focus {
    border: 1px solid $color-dark !important;
  }
}

.input-group-text{
  display: flex;
  align-items: center;
  border-right: 0;
  width: auto;
  white-space: nowrap;
  border-radius: 5px 0 0 5px;
  padding-right: 0;
  color: $color-grey;
}
.input-group-text + input{
  border-left: none;
  border-radius: 0 5px 5px 0;
}

textarea, .textarea{
  padding-top: 10px;
  padding-bottom: 10px;
  height: 100px;
}

select.form-control,
select.select, select{
  padding-right: 50px;
  -moz-appearance:none; /* Firefox */
  -webkit-appearance:none; /* Safari and Chrome */
  appearance:none;
  background-image: url("../../../images/select--arrow.svg");
  background: #fff url("../../../images/select--arrow.svg") no-repeat !important;
  background-position: right 18px center !important;
  background-size: 10px !important;
}

@mixin placeholder-style{
  font-size: $input-fz;
  color: #ABBCCC;
  font-family: $mainFont;
  font-style: normal;
  font-weight: 100;
}

::-webkit-input-placeholder {
  @include placeholder-style;
}
::-moz-placeholder {
  @include placeholder-style;
}
:-ms-input-placeholder {
  @include placeholder-style;
}
input:-moz-placeholder {
  @include placeholder-style;
}

select.select,
select,
input:not([type=checkbox]):not([type=radio]),
input[type="text"],
input[type="tel"],
input[type="email"],
input[type="password"] {
  height: 36px;
  line-height: 36px;
  color: $color-dark;
}

.SingleDatePicker{
  .SingleDatePickerInput{
    .DateInput{
      input[type="text"]{
        height: 34px;
        line-height: 1;
      }
    }
  }
}

// Error
.error {
  margin-top: 8px;
  font-family: $mainFont;
  font-size: 14px;
  font-style: italic;

  .ico {
    margin-right: 5px;
    color: $color-red;
  }
}


.form-group{
  .SingleDatePicker,
  &__input-wrapper{
    width: 100%;
  }
  .input-group{
    display: flex;
    align-items: stretch;
    justify-content: flex-start;
    flex-wrap: nowrap;
    gap: 0;

    .input-group-text{

    }
  }

  &__label{
    &-wrapper{
      display: inline-flex;

      .hint-component{
        margin-left: 3px;
        height: 24px;
      }
    }
  }

  &__checkbox{
    .form-checkbox-line{
      position: relative;
      display: flex;
      align-items: flex-start;
      min-height: 24px;
      padding-left: 36px;

      .form-check-label{
        padding-top: 2px;
      }

      .custom-checkbox{
        position: absolute;
        left: 0;
        top: 0;
        display: inline-block;
        width: 24px;
        height: 24px;
        border: 1px solid $border-color;
        box-sizing: border-box;
        border-radius: 4px;
        line-height: 1;
        padding: 0;
        margin: 0;

        &:before{
          position: absolute;
          top: 4px;
          left: 4px;
          color: $color-dark;
          font-size: 16px;
          display: inline-block;
          font-style: normal;
          font-variant: normal;
          text-rendering: auto;
          -webkit-font-smoothing: antialiased;
          font-family: "Font Awesome 5 Pro";
          font-weight: 300; content: "\f00c";
          opacity: 0;
          transform: scale(0.2);
          @include transition;
        }
      }

      .form-control{
        height: 24px;
        width: 24px;
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
      }

      .form-control:checked + .custom-checkbox{
        background: $secondary-brand-color;

        &:before{
          opacity: 1;
          transform: scale(1);
        }
      }
    }
  }

  &--custom-checkbox{
    display: block;

    .custom-checkbox{
      &--body{
        display: flex;
        justify-content: flex-start;
        gap: 5px 12px;
        align-items: baseline;
        line-height: 1;
      }
      &--title{
        padding: 0 !important;
        margin: 0 !important;
      }
      &--switcher {
        margin: 0;
        padding: 0;
        top: 0.5em;

        .react-switch-bg{
          border: 1px solid #D5E0F0 !important;
          background: rgba($secondary-brand-color, 0.5) !important;
        }
        .react-switch-handle{
          background: #D5E0F0 !important;
          height: 20px !important;
          width: 20px !important;
          top: 3px !important;
          left: 2px;
        }

        &.--unchecked{
          .react-switch-bg{
            border: 1px solid $border-color !important;
            background: rgba($secondary-brand-color, 0.5) !important;
          }
          .react-switch-handle{
            background: #D5E0F0 !important;
            left: 2px !important;
          }
        }
        &.--checked{
          .react-switch-bg{
            border: 1px solid $border-color !important;
            background: #fff !important;
          }
          .react-switch-handle{
            @include gradient--green();
          }
        }
      }
    }
  }

  &--color-picker{
    .color{
      &--list{
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 10px;
        flex-wrap: wrap;
        padding-top: 8px;

        .btn--action{
          width: 36px;
          height: 36px;
          min-width: 36px;
          border: 1px solid $border-color;
        }
      }
      &--item{
        display: flex;
        align-items: center;
        justify-content: center;
        line-height: 1;
        width: 36px;
        height: 36px;
        min-width: 36px;
        border-radius: 35px;
        transition: all 0.3s ease-in;
        color: #fff;
        text-shadow: 0px 0px 1px 0px #000;
        cursor: pointer;

        &.--unchecked{
          box-shadow: none !important;
        }
        &:hover{
          &.--unchecked{
            box-shadow: 0px 0px 10px -2px rgba($color-dark, 0.8) !important;
          }
        }
      }
    }
  }

  &--invoice-number{
    .invoice-build-number{
      display: flex;
      align-items: center;
      justify-content: flex-start;
      background: rgba($color-grey, 0.3);
      border-radius: 5px;
      min-height: 40px;
      padding: 4px 18px;
    }
  }

  &--radio-gallery{
    .radio-gallery{
      &--list{
        display: grid;
        width: 100%;
        grid-gap: 20px;
        grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
        padding-top: 8px;
      }
      &--switcher{
        position: relative;
        padding: 0 0 2px 0;
        margin: 0;
        display: block;
        text-align: left;
        background: transparent;
        border: none;
        outline: none;
      }
      &--radio{
        position: absolute;
        opacity: 0;
      }
      &--picture{
        position: relative;
        display: block;
        text-align: center;
        box-sizing: border-box;
        line-height: 1;
        border-radius: 4px;
        background: transparent;
        overflow: hidden;
        cursor: pointer;

        &:before{
          display: block;
          content: '';
          padding-top: 87.2%;
          background: transparent;
        }

        img{
          position: absolute;
          top: 50%;
          left: 50%;
          object-fit: cover;
          transform: translate(-50.199%, -50%);
          border-radius: 4px;
        }

        &:hover, &:focus{
          box-shadow: 0px 0px 20px -5px rgba($color-dark, 0.2) !important;
        }
      }
      &--icon{
        position: absolute;
        bottom: 0;
        right: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 36px;
        height: 36px;
        border-radius: 4px;
        font-size: 16px;
        line-height: 1;
        color: #fff;
        @include gradient--green();
        opacity: 0;
      }
      &--title{
        font-family: $titleFont;
        font-size: 14px;
        font-weight: bold;
        padding-top: 6px;
      }

      &--switcher{
        input:checked + .radio-gallery--picture{
          @include gradient--green();

          img{
            width: 97%;
          }

          .radio-gallery--icon{
            opacity: 1;
          }
        }
      }
    }
  }

  &__upload-file{
    .upload-file{
      &--component{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 20px;
      }
      &--preview{
        position: relative;
        width: 100%;
        max-width: 184px;
        min-width: 184px;
        min-height: 184px;
        height: 184px;
        padding: 2px;
        line-height: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        background: rgba($secondary-brand-color, 0.5);
        border: 1px dashed transparent;
        border-radius: 5px;
        text-align: center;

        > label{
          position: absolute;
          width: 100%;
          bottom: 0;
          top: 0;
          left: 0;
          padding: 10px;
          text-align: center;
          display: flex;
          align-items: center;
          opacity: 1;
          justify-content: center;
        }

        .btn{
          position: absolute;
          top: 46%;
          left: 100%;
          transform: translate(20px, 40px);
          white-space: nowrap;
          display: none;
        }

        &.--empty{
          border-color: $border-color;
        }
        &.--uploaded{
          border-color: $color-green;
          background: rgba(#fff, 0.5);

          .upload-file--cta-txt{
            opacity: 0;
          }

          .btn{
            display: inline-flex;
          }
        }
      }

      &--cta-txt{
        color: $color-dark;
        font-size: 12px;
        font-style: italic;
      }

      &--image{
        object-fit: contain;
        height: auto;
        max-height: 80px;
        max-width: 100%;
      }

      &--rules{
        padding-top: 8px;
        line-height: 1.2;
        color: $color-grey;
        font-size: 13px;
      }
    }
  }

  &__input-group{
    .input-group{
      display: flex;
      gap: 8px;
      &--wrapper{
        display: flex;
        align-items: flex-end;
        gap: 12px;
        padding-bottom: 6px;
      }
      &--item-field{
        flex: 1;
      }
    }
  }
}

.form-group__input-union{
  .form-group{
    margin-bottom: 5px;
    &:last-child{
      margin-bottom: 0;
    }
    .form-group__label-wrapper{
      display: none;
    }
  }
}

input.react-select__input{
  border: none !important;
  height: auto !important;
}

body .react-select{
  &__value-container{
    padding: 0 16px;
  }
  &__control{
    border-color: #d7dfef;
    box-shadow: none;

    &:hover{
      border-color: $color-dark;
    }
  }
  &__control--is-focused{
    border-color: $color-dark;
    box-shadow: none;
  }
  &__menu{
    box-shadow: 2px 2px 18px rgba(45, 51, 72, 0.1);
  }
}