.status-informer{
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-left: -10px;
  margin-right: -10px;

  &__item{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
    justify-content: center;
    padding: 12px 30px;
    width: calc(20% - 20px);
    max-width: 320px;
    margin: 5px 10px;
    @include break-max($mob){
      padding-left: 0;
      padding-right: 0;
    }
    @include break-max($mob-xs){
      width: calc(33% - 20px);
    }

    &-title{
      display: flex;
      align-items: center;
      font-size: 16px;
      color: $color-dark;
      margin-bottom: 5px;
    }
    &-value{
      font-size: 22px;
    }

    &__paid,
    &-pending{
      .status-informer__item-value{
        color: $color-blue
      }
    }
    &__open,
    &-received{
      .status-informer__item-value{
        color: $color-green
      }
    }
    &-canceled{
      .status-informer__item-value{
        color: $color-orange;
      }
    }
    &-error{
      .status-informer__item-value{
        color: $color-red;
      }
    }
    &__void,
    &-refunded{
      .status-informer__item-value{
        color: $color-grey;
      }
    }
  }
}