@import "vars";

// Titles
.title {
  // H1
  &--h1 {
    font-family: $titleFont;
    font-size: 34px;
    font-weight: 500;
    line-height: 34px;

    @include break-max($mob){
      font-size: 22px;
    }
  }

  // H2
  &--h3 {
    font-family: $mainFont;
    font-size: 18px;
    font-weight: 600;
    line-height: 26px;
  }
}

// Text
.p {
  font-family: $mainFont;
  font-size: 14px;
  line-height: 26px;
  color: $color-dark;

  // Secondary text
  &--secondary {
    color: $color-grey;
  }
  &--hint{
    display: flex;
    align-items: flex-start;
    padding-top: 4px;
    padding-left: 2px;
    font-size: 13px;
    line-height: 1.3;
    color: $color-grey;
    justify-content: space-between;
    gap: 0 20px;
  }
}

.mb-4{
  margin-bottom: 20px !important;
}
.mb-3{
  margin-bottom: 15px !important;
}
.mb-2{
  margin-bottom: 10px !important;
}
.mb-1{
  margin-bottom: 5px !important;
}
.mb-0{
  margin-bottom: 0 !important;
}