.no-data{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 50px 0;
  min-height: 38vh;
  margin-bottom: 10px;

  &__picture{
    width: 100%;
    max-width: 300px;
    height: 220px;
    //aspect-ratio: 16/9;
    background-size: contain;
    background-repeat: no-repeat !important;
    background-position: top center !important;
    @include break-max(550px){
      height: 0;
      padding-top: 56.25%;
      overflow: hidden;
    }

    &--user{
      background-image: url("../../../../images/no-data/no-data--user.svg");
    }
    &--user-activity-log{
      background-image: url("../../../../images/no-data/no-data--user-logs.svg");
      height: 200px;
    }
    &--transaction{
      background-image: url("../../../../images/no-data/no-data--transaction.svg");
      //margin-bottom: 10px;
    }
    &--recurring-profile{
      background-image: url("../../../../images/no-data/no-data--recurring-profile.svg");
    }
    &--payment-method{
      background-image: url("../../../../images/no-data/no-data--transaction.svg");
    }
    &--dashboard{
      background-image: url("../../../../images/no-data/no-data--dashboard-chart.svg");
    }
    &--select-custom-feature,
    &--payment-system{
      background-image: url("../../../../images/no-data/no-data--payment-system-config.svg");
      height: 180px;
      //margin-bottom: 20px;
      //@include break-max(550px){
      //  height: 0;
      //  padding-top: 56.25%;
      //  overflow: hidden;
      //}
    }
  }

  &__content{
    padding-top: 20px;
    width: 100%;
    max-width: 250px;
    text-align: center;
  }
  &__title{
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 5px;
  }
  &__descr{
    color: $color-grey;
    font-size: 14px;
  }
}

.no-data--dashboard{
  padding: 10px 0;
  min-height: 25vh;
  margin-bottom: 0;
  .no-data__content {
    max-width: 390px;
  }

  &.no-data--dashboard-v2{
    border-radius: 5px;
    padding: 20px;
    background: $secondary-brand-color;
    min-height: 300px;
    justify-content: center;
    @include break-max(1300px){
      min-height: 170px;
    }

    .no-data__content{
      max-width: unset;
      width: auto;
    }

    .no-data__title{
      font-size: 14px;
      color: $color-grey;
      display: flex;
      align-items: center;

      &:before{
        display: inline-block !important;
        margin-right: 10px;
        text-align: center;
        font-style: normal;
        font-variant: normal;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        font-family: "Font Awesome 5 Pro";
        font-weight: 300;
        font-size: 16px;
        content: '\f07c';
      }
    }
    .no-data__picture,
    .no-data__descr{
      display: none;
    }
  }
}