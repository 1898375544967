.header{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: $color-dark;
  color: #fff;
  z-index: 1041;

  &__nav-toggler{
    position: relative;
    display: none;
    width: 48px;
    height: 48px;
    background: transparent;
    border: none;
    outline: none;
    line-height: 1;
    padding: 0;
    align-items: center;
    justify-content: center;
    transition: .3s ease-in-out;
    margin-right: 10px;

    @include break-max(1200px){
      display: inline-flex;
    }

    span{
      position: absolute;
      background: $color-icons;
      height: 2px;
      width: 100%;
      max-width: 24px;
      border-radius: 3px;
      opacity: 1;
      left: 0;
      right: 0;
      margin: 0 auto;
      transition: .25s ease-in-out;

      &:nth-child(1){
        top: 15px;
        transform-origin: left center;
      }
      &:nth-child(2) {
        top: 23px;
        transform-origin: left center;
      }
      &:nth-child(3) {
        top: 31px;
        transform-origin: left center;
      }
    }

    &.active{
      span{
        background: $color-green;

        &:nth-child(1){
          transform: rotate(45deg);
          top: 16px;
          left: 8px;
        }
        &:nth-child(2) {
          width: 0%;
          opacity: 0;
        }
        &:nth-child(3) {
          transform: rotate(-45deg);
          top: 33px;
          left: 8px;
        }
      }
    }
  }

  &--step_ga_2fa,
  &--step_login_form,
  &--step_setup_password,
  &--step_ga_2fa_setup{
    background: none;
    position: relative;

    .header__nav-toggler,
    .header__user-account {
      display: none;
    }
  }

  &--step_login_form {
    .header__logo {
      display: none;
    }
  }

  &__content {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 62px;
    padding-left: 12px;

    @include break-max(1200px){
      padding-left: 0;
    }
    @include break-max($tab){
      height: 50px;
    }
  }

  &__logo {
    margin-right: auto;
    font-size: 18px;
    font-family: $titleFont;
    font-weight: 300;
    height: 36px;
    width: 150px;
    background: url("../../../../../public/img/simplymeet_theme/sm__logo-white.svg") no-repeat left center;
    background-size: 150px;

    &-image {
      margin-right: 20px;
    }
  }

  &__user-account {
    position: relative;
    margin-left: 30px;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    background: rgba($secondary-brand-color, 0.5);
    display: inline-flex;
    align-items: center;
    justify-content: center;

    .ico{
      font-size: 22px;
      color: $color-icons;
    }

    &--desk{
      @include break-max($mob){
        display: none;
      }
      .user-login-char{
        color: $color-dark;
      }
    }
    &--mob{
      background: transparent;
      @include break-min($mob){
        display: none;
      }
    }
  }

  .user-account--block{
    min-width: 200px;
    @include break-max($mob){
      padding-top: 15px;
    }

    &__header{
      position: relative;
      text-align: left;
      color: $color-dark;
      padding-bottom: 30px;

      &:before{
        content: '';
        position: absolute;
        display: block;
        width: 86%;
        height: 1px;
        left: 0;
        right: 0;
        margin: 0 auto;
        background: $border-color;
        bottom: 14px;
      }

      &-item{
        font-size: 14px;
        padding: 0 20px;
        @include break-max($mob){
          padding: 0 12px;
        }
      }
    }
    &__position{
      font-style: italic;
      line-height: 1;
      color: $color-blue;
    }

    &__name{
      font-weight: $bold;
    }

    .actions-component__item{
      @include break-max($mob){
        padding-left: 12px;
      }
    }
  }

  &__user-account:hover{
    .user-account--block{
      top: 36px;
      display: block;
      right: 0;
      left: auto;
    }
  }

  &--default{
    .header{
      &__content{
        @include break-max($mob){
          justify-content: space-between;
        }
      }
      &__logo{
        @include break-max($mob){
          overflow: hidden;
          width: 32px;
          height: 30px;
          margin-left: 10px;
          margin-right: 10px;
          background-size: 164px;

          svg{
            width: 135px;
            min-width: 135px;
            .header__logo-txt{
              display: none;
            }
          }
        }
      }
    }
  }
}

.modal--user-account{
  .modal{
    &__dialog{
      margin-top: 0;
      max-width: 100%;
      border-radius: 0;
      padding: 0 0 20px 0;
    }
    &__header{
      background: $color-dark;
      border: none;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      height: 50px;
      padding: 0 20px;
    }
    &__body{
      margin: 0;
    }
    &-content{
      border: none;

      .user-account{
        &--block{
          &__header{
            &:before{
              width: calc(100% - 40px);
              @include break-max($mob){
                width: calc(100% - 20px);
              }
            }

            .actions-component__item{
              &:before{
                display: inline-block !important;
                min-width: 15px;
                margin-right: 10px;
                text-align: center;
                font-style: normal;
                font-variant: normal;
                text-rendering: auto;
                -webkit-font-smoothing: antialiased;
                font-family: "Font Awesome 5 Pro";
                font-weight: 300;
                color: $color-icons;
              }
            }
          }
          &__header-item {
            font-size: 16px;
          }
          &-item-account{
            &:before{
              content: '\f1de';
            }
          }
          &-item-logout{
            &:before{
              content: '\f08b';
            }
          }
        }
      }
    }
  }
}