.modal__additional-actions{
  &.fade{
    .modal__dialog{
      transform: translate(0, 50px);
    }
  }
  &.show .modal__dialog {
    transform: none;
  }
  .modal{
    &__dialog{
      padding: 30px 0;
      margin: 0;
      position: absolute;
      width: 100%;
      max-width: 100%;
      border-radius: 0;
      bottom: 0;
      top: auto;

      .actions-component__item{
        display: flex;
        justify-content: flex-start;
        height: 28px;
        width: 100%;
        border-radius: 0;
        border: none;
        box-shadow: none;
        background: transparent;
        padding: 0 22px;
        margin: 0;

        &:before{
          @include break-max($mob){
            margin-right: 15px;
          }
        }

        &.has-icon__logout{
          color: $color-red;

          &:before{
            color: $color-red;
            opacity: 0.6;
          }

          &:hover{
            &:before{
              opacity: 1;
            }
          }
        }
      }
    }
    &__body{
      margin: 0;
      padding-left: 0;
      padding-right: 0;
    }
  }
}

.modal__actions-toggler{
  display: none;
  padding: 0;
  line-height: 1;
  font-size: 22px;
  width: 36px;
  border-radius: 50%;
  border: none;
  outline: none;
  color: $color-dark;
  background: $secondary-brand-color;
  opacity: 1;
  cursor: pointer;
  @include transition;
  @include break-max($tab){
    display: inline-flex;
  }

}