.btn-bar{
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-left: -5px;
  margin-right: -5px;

  .btn{
    margin: 5px;
  }
}