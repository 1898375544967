.chart{
  &__wrapper{
    position: relative;
    display: block;
    width: 100%;
    padding-top: 10px;
  }
  &__row{
    height: 100%;
    display: flex;
    align-items: stretch;
    justify-content: flex-start;
    background: $secondary-brand-color;
    border-radius: 2px;
    @include break-max($mob - 1){
      flex-direction: column-reverse;
    }
  }
  &__row-item{
    width: 100%;

    &--chart{
      position: relative;
      min-height: 300px;
      min-width: calc( 100% - 300px );
      background: transparent;
      padding: 20px 10px;
      @include break-max($mob - 1){
        padding-top: 40px;
        max-width: 100%;
      }

      .chartjs-size-monitor{
        width: 100%;
      }
      .chartjs-render-monitor{
        max-height: 260px;
        width: 1px;
        max-width: 100%;
        min-width: 100%;
      }
    }
    &--legend{
      display: flex;
      align-items: center;
      flex-direction: column;
      padding: 25px 0 25px 18px;
      position: relative;
      //width: 215px;
      //min-width: 215px;
      width: 250px;
      min-width: 250px;
      background: #FFF;
      box-shadow: 2px 2px 15px rgba(45, 51, 72, 0.1);
      border-radius: 2px;
      @include break-max($mob - 1){
        width: 100%;
      }
    }
  }
}

.custom-chart{
  &__legend {
    display: block;
    width: 100%;
    padding: 0 17px 0 0;
    margin: 0;
    list-style: none;
    max-height: 250px;
    overflow: auto;

    &::-webkit-scrollbar {
      width: 3px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: $color-blue;
      outline: none;
      border: none;
      border-radius: 10px;
    }
    &::-webkit-scrollbar-track {
      border-radius: 10px;
      -webkit-box-shadow: inset 0 0 4px rgba(0,0,0,0.2);
      background: $secondary-brand-color;
    }
  }
  &__legend-header,
  &__legend-item{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 30px;
    border-radius: 40px;
    padding: 2px 16px;
    cursor: pointer;
    margin-bottom: 5px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    &:before, &:after{
      content: '';
      display: block;
      width: 8px;
      height: 1px;
      position: absolute;
      right: 15px;
      background: $color-grey;
      top: 14px;
      transition: all 0.3s ease;
      opacity: 0;
    }

    &:after{
      transform: rotate(90deg);
    }

    &:hover{
      background: $secondary-brand-color;

      &:before, &:after{
        opacity: 1;
      }
    }

    &:last-child{
      margin-bottom: 0;
    }
  }
  &__legend-data{
    display: flex;
    align-items: center;
  }
  &__legend-header-circle,
  &__legend-circle{
    display: block;
    height: 5px;
    width: 5px;
    min-width: 5px;
    border-radius: 50%;
    margin-right: 12px;
    opacity: 0.6;
    filter: grayscale(100%);
  }
  &__legend-title{
    font-size: 12px;
    margin-right: 5px;
    line-height: 1;
    color: $color-grey;
  }
  &__legend-subtitle{
    font-size: 12px;
    line-height: 1;
    font-weight: $bold;
  }

  &__legend-item--active{
    background: $secondary-brand-color;
    &:hover{
      background: darken( $secondary-brand-color, 4% );

      &:before{
        opacity: 1;
      }
      &:after{
        display: none;
      }
    }
    .custom-chart{
      &__legend-circle{
        filter: grayscale(0);
        opacity: 1;
      }
      &__legend-title{
        color: $color-dark;
      }
    }
  }

  &__legend-item--base_currency{
    background-color: red;
    display: none;
  }

  &__legend-header{
    width: calc(100% - 16px);
    height: auto;
    margin-bottom: 14px;
    padding-right: 0;
    margin-left: auto;
    margin-right: 16px;
    padding-left: 4px;
    padding-top: 0;
    padding-bottom: 12px;
    background: transparent;
    border-bottom: 1px solid $border-color;
    border-radius: 0;
    &:last-child{
      margin-bottom: 12px;
    }
    &:before,&:after{
      display: none;
    }
    &:hover{
      background: transparent;
    }
    &.custom-chart__legend-item--active{
      background: transparent;

      .custom-chart__legend-header-circle{
        filter: none;
      }
    }
  }
  &__legend-header-circle{
    position: relative;
    top: -5px;
  }
  &__legend-header-title{
    font-size: 16px;
    font-weight: 500;
    line-height: 1;
    margin-bottom: 4px;
  }
  &__legend-header-subtitle{
    font-size: 12px;
    line-height: 1.3;
    white-space: normal;
  }
}