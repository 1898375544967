.modal__change-password,
.modal__change-2fa{
  .modal{
    &__body{
      .change-password{
        &__col{
          margin-bottom: 30px;
          &:last-child{
            margin-bottom: 0;
          }
          .form-group{
            margin-bottom: 8px;
            &:last-child{
              margin-bottom: 0;
            }


            &__label{
              padding-left: 18px;
              color: $color-grey;
            }
          }

          &-txt{
            font-size: 14px;
            margin-bottom: 0;
          }
          &-ga{
            margin-bottom: 0;
          }

          .ga{
            &__qr-code{
              text-align: center;
              padding: 12px 0;
              margin-bottom: 10px;
              img{
                height: 100px;
              }
            }
            &__hint-text{
              display: flex;
              align-items: center;
              justify-content: space-between;

              .btn{
                padding-right: 0;
                padding-left: 5px;
                margin-left: 15px;
                color: $color-blue;
                min-width: 100px;
              }
            }
          }
        }
      }
    }
    &__footer{
      .btn-bar{
        justify-content: flex-end;
        @include break-max($mob){
          justify-content: center;
        }
      }
    }
  }
}