.DateRangePicker{
  .DateRangePickerInput{
    display: inline-flex;
    align-items: center;
  }
}
.DateInput_input {
  font-weight: 500;
  font-size: 14px;
  color: $color-dark;
  border: none !important;
  //background: transparent !important;
  border-radius: 4px;
  padding: 5px;
  &:focus{
    border: none !important;
  }
}

.page__dashboard{
  .DateInput {
    width: 86px;
    min-width: 86px;
  }
}

.DateInput{
  width: 100%;
}

.SingleDatePickerInput__withBorder,
.DateRangePickerInput__withBorder {
  width: 100%;
  border-radius: 4px;
  border: 1px solid #d7dfef;
  padding-left: 10px;
}


//mob view --- custom
.content-component{

  .DateRangePicker_picker {
    position: absolute;
    right: 0 !important;
    left: auto !important;
  }

  .CalendarMonthGrid__horizontal{
    width: auto !important;
  }

  .DayPicker__withBorder {
    box-shadow: 2px 2px 18px rgba(45, 51, 72, 0.2);
    border-radius: 5px;
    padding-bottom: 20px;
  }

  .DateInput_fang{
    display: none;
    visibility: hidden;
  }

  .SingleDatePicker_picker,
  .DateRangePicker_picker{
    .DayPicker{
    }

    .DayPickerNavigation_svg__horizontal{
      display: none;
    }
    .DayPickerNavigation_button__horizontalDefault{
      top: 24px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      height: 33px;
      width: 40px;
      padding: 0;
      margin: 0;
      line-height: 1;
      border: none;
      outline: none;

      &:before{
        position: relative;
        display: inline-block !important;
        text-align: center;
        font-style: normal;
        font-variant: normal;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        font-family: "Font Awesome 5 Pro";
        font-weight: 300;
        font-size: 16px;
        color: $color-dark;
      }
    }
    .DayPickerNavigation_leftButton__horizontalDefault{
      &:before{
        content: '\f30a';
      }
    }
    .DayPickerNavigation_rightButton__horizontalDefault{
      &:before{
        content: '\f30b';
      }
    }

    .CalendarMonth_caption{
      color: $color-dark;
      font-size: 16px;
      font-weight: bold;
      padding-top: 30px;
      padding-bottom: 44px;
    }

    .CalendarMonth_table {
      border-collapse: separate;
      border-spacing: 0 2px;
    }

    .CalendarDay__default {
      position: relative;
      border: none;
      color: $color-grey;
      border-radius: 50%;
      width: 36px !important;
      height: 36px !important;

      &:hover{
        background: darken($secondary-brand-color, 2%);
        color: $color-dark;
      }
    }

    .CalendarDay__selected{
      border-radius: 50%;
      @include gradient--dark-blue;
      color: #fff;
      position: relative;
      z-index: 3;
    }
    .CalendarDay__selected:active, .CalendarDay__selected:hover{
      @include gradient--dark-blue;
      color: #fff;
    }



    .CalendarDay__selected_span{
      position: relative;
      background: $secondary-brand-color;
      color: $color-dark;
      border-radius: 0;

      &:hover{
        border-radius: 0;
      }
    }

    .CalendarDay__selected_span + .CalendarDay__selected,
    .CalendarDay__selected + .CalendarDay__selected_span{
      &:before{
        content: '';
        display: block;
        width: 40%;
        height: 100%;
        position: absolute;
        top: 0;
        right: 100%;
        background: $secondary-brand-color;
        z-index: 0;
      }
    }
    .CalendarDay__selected_span + .CalendarDay__selected{
      &:before{
        right: auto;
        left: 0;
        display: none;
      }
      box-shadow: -8px 0px 2px 1px $secondary-brand-color;
    }

    .DayPicker_weekHeader{
      top: 72px;
    }

    .DayPicker_weekHeader_ul{
      .DayPicker_weekHeader_li{
        small{
          font-size: 16px;
          color: $color-dark;
          font-weight: bold;
        }
      }
    }
  }

  @include break-max(990px){
    .SingleDatePicker_picker,
    .DateRangePicker_picker{
      position: fixed;
      top: 140px  !important;
      left: 0  !important;
      right: 0  !important;
      margin: 0 auto  !important;
      bottom: auto  !important;
      //z-index: 999 !important;
      z-index: 1042 !important;
      width: 100%;
      max-width: 300px;

      //phantom datepicker header
      &:before{
        content: ' ';
        display: block;
        width: 100%;
        height: 50px;
        position: absolute;
        left: 0;
        top: -50px;
        background: $color-dark;
        user-select: none;
        pointer-events: none;
      }

      .DayPicker{
        border-radius: 0;
        margin: 0 auto;
        //phantom datepicker header close button
        &:before,&:after{
          content: '';
          display: inline-block;
          position: absolute;
          background: $color-icons;
          height: 2px;
          width: 100%;
          max-width: 24px;
          border-radius: 3px;
          opacity: 1;
          top: -28px;
          right: 10px;
          margin: 0 auto;
          transform: rotate(45deg);
        }
        &:after{
          transform: rotate(-45deg);
        }

        > div{
          > div{
            width: 100% !important;
          }
        }
      }

      .DayPicker_weekHeader_ul{
        display: flex;
        align-items: stretch;
        width: 100%;
        justify-content: space-between;
      }
    }
  }
}

.SingleDatePickerInput_clearDate__default,
.SingleDatePickerInput_clearDate{
  color: $color-dark;
  background: #fff;
  padding: 6px;
  border-radius: 4px;
  width: 32px;
  height: 32px;
  line-height: 1;
  .SingleDatePickerInput_clearDate_svg{
    fill: $color-dark;
  }
  &:hover, &:focus{
    color: $color-red;
    background: #fff;
    border-radius: 4px;
    .SingleDatePickerInput_clearDate_svg{
      fill: $color-red;
    }
  }
}
