.export{
  &-component{
    background: $secondary-brand-color;
    margin-bottom: 30px;
    box-shadow: none;

    .filer-component__wrapper{
      &.__export{
        background: $secondary-brand-color;

        @include break-max($tab){
          background: #fff;
        }
      }
    }

    .filer-component__header{
      &.__export{
        &:after{
          background: $border-color;
        }
      }
    }

    .filer-component__form{
      &.__export{
        @include break-min($tab){
          padding-top: 15px;
        }
      }
    }

    &.expanded{
      .filer-component__header{
        &.__export{
          &:after{
            background: darken($border-color, 4%);
          }
        }
      }
    }
  }

  &__list{
    display: grid;
    width: 100%;
    grid-gap: 14px;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    @include break-max($tab){
      padding-top: 10px;
      height: 100%;
      max-height: calc(100vh - 160px);
      overflow: auto;
    }
  }

  &__item{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    min-height: 50px;
    width: 100%;
    background: #fff;
    border-radius: 5px;
    padding: 10px 80px 10px 18px;
    cursor: pointer;
    transition: all 0.3s ease-out;
    @include break-max($tab){
      background: $secondary-brand-color;
      min-height: 62px;

      &:active{
        background: darken($secondary-brand-color, 4%);
      }
    }

    &-content{
      width: 100%;

      p{
        margin: 0;
        line-height: 1;
      }
    }
    &-date{
      font-size: 12px;
      padding-top: 5px;
      color: $color-grey;
    }

    &-type,
    &-status{
      position: absolute;
      top: 50%;
      right: 15px;
      transform: translateY(-50%);
      width: 25px;
      height: 30px;
      text-align: center;
      line-height: 30px;
      cursor: pointer;

      &:before{
        font-family: "Font Awesome 5 Pro";
        font-weight: 300;
        display: inline-block;
        text-align: center;
        font-style: normal;
        font-variant: normal;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        line-height: 30px;
        font-size: 15px;
      }

      &.completed{
        &:before{
          font-weight: 400;
          color: $color-green;
          content: "\f019";
        }
      }

      &.processing{
        &:before{
          color: $color-grey;
          content: "\f110";
          font-size: 17px;
          animation: spin 2.5s linear infinite;
        }
      }

      &.recurring_profile{
        right: 46px;
        &:before{
          color: $color-dark;
          content: "\f364";
        }
      }
    }

    &-processing{
      background: rgba(#fff, 0.7);
      cursor: default;

      @include break-max($tab){
        background: rgba($secondary-brand-color, 0.7);
        &:active{
          background: rgba(darken($secondary-brand-color, 4%), 0.7);
        }
      }
    }
  }
}

.export-component{
  .filer-component{
    &__wrapper{
      .modal-content{
        padding-bottom: 0;
        @include break-max($tab){
          padding-bottom: 20px;
        }
      }
    }

    &__btn-bar{
      display: none;
    }
  }
}

.mobile-bar{
  &__filter-export{
    @include break-max($tab){
      display: flex;
      margin-left: -5px;
      margin-right: -5px;
    }

    &-item{
      @include break-max($tab){
        margin: 0 5px;
      }

      &.__filter{
        width: 100%;
      }

      &.__export{
        display: none;
        min-width: 120px;
        @include break-max($tab){
          display: block;
        }
      }
    }
  }
}

@keyframes spin {
  from{
    transform: rotate(0deg);
  }
  to{
    transform: rotate(1turn);
  }
}