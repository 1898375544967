// use in uikit.scss

//font styles
$titleFont: "Mulish", sans-serif;
$mainFont: "NotoSans", sans-serif;

$font-family-monospace:   Menlo, Monaco, Consolas, "Courier New", monospace !default;

// Responsiveness breakpoints
$fullHD: 1920px;
$lap: 1366px;
$tab: 1023px;
$mob: 767px;
$mob-xs: 480px;


// Colors
$white: #fff;
$color-dark: #0B3052;
$color-grey: #788291;
$color-blue: #06ADEF;
$color-green: #00D38B;
$color-red: #FF3259;
$color-orange: #FEC439;
$color-icons: #ABBCCC;
$secondary-brand-color: #F1F6FF;
$border-color: #E7ECF7;
$border-color--focus: #0B3052;
$print-invoice--color-dark: #0B3052;

@mixin gradient--dark-blue{
  background: linear-gradient(139.93deg, #3873CD 18.92%, #0B3052 87.06%);
}
@mixin gradient--blue{
  background: linear-gradient(254.44deg, #06ADEF -55.67%, rgba(6, 173, 239, 0) 36.52%),
  linear-gradient(95.84deg, rgba(0, 211, 139, 0.5) -56.62%, rgba(0, 211, 139, 0) 68.64%), #06ADEF !important;
  background-blend-mode: multiply, normal, normal;
  transition: all 0.3s ease;
}
@mixin gradient--green{
  background: linear-gradient(281.44deg, rgba(0, 211, 139, 0.5) 33.12%, rgba(6, 173, 239, 0.7) 100%), #00D38B !important;
  transition: all 0.3s ease;
}

@mixin gradient--blue-hover{
  background: linear-gradient(95.84deg, rgba(0, 211, 139, 0.5) -56.62%, rgba(0, 211, 139, 0) 68.64%),
  linear-gradient(254.44deg, #06ADEF -55.67%, rgba(6, 173, 239, 0) 36.52%), #06ADEF !important;
  background-blend-mode: multiply, normal, normal;
  transition: all 0.3s ease;
}

@mixin gradient--green-hover{
  background: linear-gradient(281.44deg, rgba(6, 173, 239, 0.5) 104.11%, rgba(0, 211, 139, 0.5) 33.12%), #00D38B !important;
  transition: all 0.3s ease;
}


$h2: 34px;
$h3: 18px;
$main-text: 14px;
$input-fz: 16px;
$small-text: 12px;

// Font weight
$light: 300;
$normal: 400;
$semibold: 500;
$bold: 500;

$item-maxw: 400px;

@mixin break-min($point) {
  @media (min-width: $point) {
    @content;
  }
}

@mixin break-max($point) {
  @media (max-width: $point) {
    @content;
  }
}

@mixin transition{
  transition: all .25s ease;
}

@include break-max($tab){
  $main-text: 16px;
}