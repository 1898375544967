.status{
	position: relative;
	min-width: 80px;
	display: inline-flex;
	font-size: 12px;
	height: 24px;
	align-items: center;
	padding: 4px 14px;
	border-radius: 4px;
	line-height: 1;
	font-style: italic;
	@include break-max($mob){
		min-width: 26px;
	}

	&:before{
		display: inline-block;
		font-style: normal;
		font-variant: normal;
		text-rendering: auto;
		-webkit-font-smoothing: antialiased;
		font-family: "Font Awesome 5 Pro";
		font-weight: 300;
		margin-right: 6px;
		font-size: 12px;
	}

	&__true,
	&__active,
	&__received{
		color: $color-green;
		background: rgba($color-green, 0.1);

		&:before{
			content: "\f00c";
		}
	}
	&__false,
	&__void,
	&__closed,
	&__canceled{
		color: $color-red;
		background: rgba($color-red, 0.1);

		&:before{
			content: "\f00d";
		}
	}
	&__error{
		color: $color-red;
		background: rgba($color-red, 0.1);

		&:before{
			content: "\f071";
		}
	}
	&__paid{
		color: darken($color-green, 4%);
		background: rgba($color-green, 0.16);

		&:before{
			content: "\f058";
		}
	}
	&__open,
	&__pending{
		color: $color-grey;
		background: rgba($secondary-brand-color, 0.8);

		&:before{
			content: "\f017";
		}
	}
	&__new{
		color: $color-blue;
		@include break-min($mob){
			background: rgba($color-blue, 0.1);
		}

		&:before{
			content: "\f067";
		}
	}
	&__http{
		color: $color-grey;
		background: rgba($secondary-brand-color, 0.8);
	}
	&__refunded,
	&__inactive{
		color: $color-grey;
		@include break-min($mob){
			background: rgba($secondary-brand-color, 0.8);
		}

		&:before{
			content: "\f122";
		}
	}
	&__inactive{
		&:before{
			content: "\f00d";
		}
	}
}