.super-admin--page-login{
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  @include gradient--dark-blue;
  animation: gradient 8s ease infinite;
  background-size: 150% 150%;

  .footer--phantom{
    min-height: 64px;
  }

  .container{
    position: relative;
    z-index: 3;
  }

  .login-form{
    &__wrapper{
      width: 94%;
      max-width: 400px;
      margin: 30px auto;
    }

    &__title{
      color: $white;
      font-size: 16px;
      line-height: 1.3;
      margin-bottom: 15px;
      font-weight: $light;
    }

    &__logo{
      text-align: center;
      margin-bottom: 50px;

      &-picture{
        display: inline-block;
      }

      &-title {
        margin-top: 20px;
      }
    }

    &__content{
      .form-group{
        margin-bottom: 10px;
        &__label-wrapper,
        label{
          display: none;
        }

        &__input-error {
          color: $white;
        }
      }
    }

    &__text {
      margin-bottom: 15px;
      font-family: 'Gilroy', sans-serif;
      font-size: 16px;
      font-weight: 300;
      line-height: 22px;
      color: $white;
    }

    &__btn-bar{
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      padding-top: 5px;
      //margin: 0 -5px 130px -5px;
      margin: 0 -5px 50px -5px;
      &--ga{
        justify-content: space-between;
      }

      @include break-max($mob){
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }

      .btn{
        margin: 5px;

        &--continue-login{
          min-width: 150px;
          @include break-max($mob){
            min-width: 180px;
            margin-bottom: 12px;
          }
        }
      }
    }

    &__create-account{
      text-align: center;

      &-title{
        color: $white;
        margin-bottom: 8px;
      }
    }

    &__content-picture-ga{
      text-align: center;
      line-height: 1;
      margin-bottom: 10px;

      img{
        height: 100px;
        display: inline-block;
      }
    }
  }

  .login-form--ga{
    .login-form__btn-bar{
      margin-bottom: 0;
    }
  }
}


@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}