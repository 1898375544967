.actions-component{
  position: relative;
  display: inline-block;
  width: 30px;
  height: 30px;

  &--actived{
    z-index: 99;
  }

  &__toggler{
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    font-size: 22px;
    border-radius: 50%;
    border: none;
    outline: none;
    color: $color-dark;
    opacity: .5;
    cursor: pointer;
    @include transition;

    &-icon{
      &.fa-eye{
        font-size: 14px;
      }
    }

    &:hover{
      @include break-min($mob){
        background: #E7ECF7;
        opacity: 1;
      }
    }

    &--toggler {
      background: #E7ECF7;
      opacity: 1;
    }
  }

  &__list{
    display: none;
    @include break-min($mob){
      position: absolute;
      top: 30px;
      right: -15px;
      min-width: 165px;
      padding: 14px 0;
      border-radius: 5px;
      background: $white;
      box-shadow: 2px 2px 15px rgba(45, 51, 72, 0.1);
      z-index: 99;
    }

    &:before{
      content: '';
      display: block;
      position: absolute;
      width: 100%;
      height: 20px;
      top: -14px;
      left: 0;
      opacity: 0;
    }
    @include break-max($mob){
      position: fixed;
      display: block;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 1;
      visibility: hidden;
      z-index: 999;
      overflow: hidden;
      transition: all 0.2s ease;

      &:before{
        content: ' ';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        @include gradient--dark-blue;
        opacity: 0.5;
      }
    }

    &--opened {
      display: block;
      @include break-max($mob){
        visibility: visible;

        .actions-component__content{
          transform: none;
        }
      }
    }
  }

  &__content{
    @include break-max($mob){
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      background: #fff;
      border-radius: 0;
      padding: 30px 0;
      box-shadow: 2px 2px 15px rgba(45, 51, 72, 0.1);
      transform: translate(0, 100px);
      transition: transform 0.3s ease-out;
    }
  }

  &__item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    min-height: 28px;
    margin-bottom: 0;
    font-size: 14px;
    line-height: 1;
    padding: 0 20px;
    color: $color-grey;
    cursor: pointer;
    @include transition;

    &:before{
      display: inline-block !important;
      min-width: 15px;
      margin-right: 10px;
      text-align: center;
      font-style: normal;
      font-variant: normal;
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;
      font-family: "Font Awesome 5 Pro";
      font-weight: 300;
      color: $color-icons;
      @include break-max($mob){
        margin-right: 15px;
      }
    }

    &-edit{
      &:before{
        content: "\f303";
      }
    }
    &-view{
      &:before{
        font-size: 16px;
        content: "\f05a";
      }
    }
    &-prolong-recurring{
      &:before{
        font-size: 16px;
        content: "\f364";
      }
    }
    &-recurring{
      &:before{
        font-size: 16px;
        content: "\f364";
      }
    }
    &-confirm{
      &:before{
        content: '\f00c';
      }
    }
    &-enable{
      color: $color-green;
      &:before{
        color: $color-green;
        content: '\f00c';
        opacity: 0.8;
      }

      &:hover {
        color: $color-green;

        &:before{
          color: $color-green;
          opacity: 1;
        }
      }
    }
    &-settings{
      &:before{
        content: '\f085';
      }
    }
    &-view-web{
      &:before{
        content: '\f0c1';
      }
    }
    &-invoice{
      &:before{
        font-size: 16px;
        content: "\f570";
      }
    }
    &-print{
      &:before{
        font-size: 16px;
        content: "\f02f";
      }
    }

    &:hover {
      color: $color-dark;
      text-decoration: none;
      //background: $secondary-brand-color;

      &:before{
        color: $color-grey;
      }
    }

    &-disable {
      color: $color-orange;

      &:before{
        color: $color-orange;
        content: "\f05e";
        opacity: 0.8;
      }

      &:hover {
        color: $color-orange;

        &:before{
          color: $color-orange;
          opacity: 1;
        }
      }
    }

    &-delete {
      color: $color-red;

      &:before{
        color: $color-red;
        content: "\f1f8";
        opacity: 0.8;
      }

      &:hover {
        color: $color-red;

        &:before{
          color: $color-red;
          opacity: 1;
        }
      }
    }

    &-resetSecondFactor {
      color: $color-orange;

      &:before{
        color: $color-orange;
        content: "\f05e";
        opacity: 0.8;
      }

      &:hover {
        color: $color-orange;

        &:before{
          color: $color-orange;
          opacity: 1;
        }
      }
    }

    @include break-max($tab){
      font-size: 16px;
      min-height: 40px;
      margin-bottom: 2px;
    }
    @include break-max($mob){
      color: $color-dark;
      padding: 0 25px 0 32px;
    }
  }

  &:hover{
    .actions-component__toggler{
      @include break-min($mob){
        background: #E7ECF7;
        opacity: 1;
      }
    }
    .actions-component__list,
    .actions-component__desk{
      @include break-min($mob){
        display: block;
      }
    }
  }
}

.btn-bar__adaptive-wrapper{
  flex: 1;
  width: 100%;
  display: block;
  .btn-bar__row{
    justify-content: flex-end;
    display: flex;
    gap: 2px 10px;
    margin-left: 0;
    margin-right: 0;

    .btn{
      margin: 0;
    }
  }
  .dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    display: none;
    float: left;
    min-width: 140px;
    min-width: 10rem;
    padding: 16px 0;
    margin: 0.125rem 0 0;
    font-size: 14px;
    color: #788291;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: none;
    border-radius: 5px;
    box-shadow: 2px 2px 15px rgba(45, 51, 72, 0.1);
    z-index: 1000;

    .actions-component__item{
      border-radius: 0;
      min-height: 30px;
      padding: 4px 22px;
      justify-content: flex-start;
      background: transparent;
      margin: 0;
      border: none;
      font-weight: normal;
      color: $color-dark;

      &:before{
        color: $color-grey;
      }

      &:hover, &:active{
        color: $color-dark;
        background: $secondary-brand-color;

        &:before{
          color: $color-dark;
        }
      }
    }

    &.show{
      display: block;
    }
  }
}