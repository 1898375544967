/*!
 * Font Awesome Pro 5.12.0 by @fontawesome - https://fontawesome.com
 * License - https://fontawesome.com/license (Commercial License)
 */
@import "variables";
//@import 'variables';
@import 'solid';
@import 'light';
@import 'brands';
@import 'regular';
//@import "duotone";
@import 'mixins';
@import 'core';
//@import 'larger';
//@import 'fixed-width';
//@import 'list';
//@import 'bordered-pulled';
//@import 'animated';
//@import 'rotated-flipped';
//@import 'stacked';
@import 'icons';
//@import 'sb-icons';
@import 'screen-reader';
