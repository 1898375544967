.swipe-area {
  float: left;
  left: 0;
  top: 0;
  position: fixed;
  width: 23px;
  height: 100%;
  background: transparent;
  z-index: 10;
}

.sidebar{
  position: fixed;
  left: 0;
  top: 62px;
  background: $secondary-brand-color;
  transition: all 0.3s ease-out;
  @include break-min($mob){
    height: calc(100% - 64px);
    width: 225px;
  }

  @include break-max(1200px){
    left: -100%;
    background: #fff;
    border-right: 1px solid $color-green;
  }
  @include break-max($tab){
    top: 50px;
    height: calc(100% - 50px);
  }
  @include break-max($mob){
    height: auto;
    left: -100%;
    right: auto;
    width: 100%;
    top: 50px;
    opacity: 0.5;
    border-right: none;
    padding-bottom: 16px;
    border-bottom: 1px solid $color-green;
  }

  &__visible{
    left: 0;
    z-index: 99992;

    @include break-max($mob){
      left: 0;
      opacity: 1;
    }
  }

  &__cover{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: transparent;
    visibility: hidden;
    transition: all 0.1s ease-out;
    display: none;

    &:before{
      content: '';
      display: block;
      width: 100%;
      position: absolute;
      top: 50px;
      bottom: 0;
      left: 0;
      right: 0;
      @include gradient--dark-blue;
      opacity: 0.5;
      @include break-min($tab){
        top: 60px;
      }
    }

    &--hidden{
      visibility: hidden;
      z-index: -1;
    }

    &--visible{
      @include break-max(1200px){
        display: block;
      }
      visibility: visible;
      z-index: 99991;
    }
  }

  &__toggler{
    position: fixed;
    top: 0;
    left: 20px;
    width: 48px;
    height: 48px;
    z-index: 123;
    display: none;
  }

  &__content{
    height: 100%;
  }
  &__menu{
    padding-top: 30px;
    background: transparent;
    @include break-max($mob){
      padding-top: 16px;
    }
  }
  &__menu-list{
    margin: 0;
    padding: 0;
    list-style: none;

    &--inner{
      @include break-min($mob){
        padding-left: 36px;
        &:after{
          content: '';
          display: block;
          width: 100%;
          height: 18px;
        }
      }
    }
  }

  .menu-list{
    &--item{
      display: block;
      margin: 0 0 4px 0;
    }
    &--item--has-dropdown{
      @include break-max($mob){
        padding: 10px 0;
        border-bottom: 1px solid $border-color;
      }
      .sidebar__menu-list--inner{
        transition: all 0.4s ease-out;
        overflow: hidden;
      }
      &.__hidden{
        .menu-list--item-trigger{
          .arrow{
            &:before{
              transform: rotate(-90deg);
            }
          }
        }
        .sidebar__menu-list--inner{
          max-height: 0;
          transition: all 0.4s ease-out;
          @include break-max($mob){
            max-height: 100%;
          }
        }
      }
      &.expanded{
        .sidebar__menu-list--inner{
          max-height: 400px;
          transition: all 0.4s ease-in;
        }
      }
    }
    &--item-trigger{
      position: relative;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      min-height: 40px;
      font-size: 14px;
      text-decoration: none;
      font-weight: $bold;
      color: $color-dark;
      margin: 0;
      padding: 4px 25px 4px 30px;
      cursor: pointer;
      @include transition;
      &:before{
        font-family: "Font Awesome 5 Pro";
        font-weight: 300;
        display: inline-block;
        text-align: center;
        font-style: normal;
        font-variant: normal;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        width: 25px;
        font-size: 16px;
        min-width: 25px;
        margin-right: 10px;
        background-position: center !important;
        background-repeat: no-repeat !important;
      }
      .arrow{
        margin: 0 0 0 15px;
        width: 20px;
        min-width: 20px;
        text-align: center;
        display: inline-flex;
        align-items: center;
        justify-content: center;

        &:before{
          font-family: "Font Awesome 5 Pro";
          font-weight: 300;
          display: inline-block;
          text-align: center;
          font-style: normal;
          font-variant: normal;
          text-rendering: auto;
          -webkit-font-smoothing: antialiased;
          font-size: 16px;
          content: '\f107';
          transition: all 0.3s ease-in;
        }
      }
      &.dashboard{
        &:before{
          content: "\f080";
        }
        &:after{
          display: none;
        }
      }
      &.payments{
        &:before{
          content: "\f53a";
        }
      }
      &.users{
        &:before{
          content: "\f6a5";
        }
      }
      &.activity-log{
        &:before{
          content: "\f1da";
        }
      }
      &.customers{
        &:before{
          content: "\f500";
        }
      }
      &.profile{
        &:before{
          content: "\f2c2";
        }
      }
      &.reports{
        &:before{
          content: "\f659";
        }
      }
      &.merchant{
        min-height: 40px;
        &:before{
          content: "\f49c";
        }
        &:after{
          display: none;
        }
      }
      @include break-max($mob){
        display: none;
      }
    }
    &--item-link{
      position: relative;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      min-height: 24px;
      font-size: 14px;
      line-height: 1.3;
      text-decoration: none;
      font-weight: $light;
      color: $color-dark;
      margin: 0;
      padding: 0 20px 0 30px;
      @include transition;
      &.menu-list--item-trigger{
        @include break-min($mob){
          font-weight: $bold;
        }
      }
      &:before{
        font-family: "Font Awesome 5 Pro";
        font-weight: 300;
        display: inline-block;
        text-align: center;
        font-style: normal;
        font-variant: normal;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        width: 25px;
        font-size: 16px;
        min-width: 25px;
        margin-right: 10px;
        background-position: center !important;
        background-repeat: no-repeat !important;
        @include break-max($mob){
          color: $color-icons;
          margin-right: 15px;
        }
      }
      @include break-max($mob){
        min-height: 40px;
        font-size: 16px;

        &.profile{
          &:before{
            content: "\f2bb";
          }
        }
        &.merchant{
          &:before{
            content: "\f47f";
          }
        }
        &.users{
          &:before{
            content: "\f500";
          }
        }
        &.activity-log{
          &:before{
            content: "\f1da";
          }
        }
        &.activity-log{
          &:before{
            content: "\f187";
          }
        }
        &.orders{
          &:before{
            content: "\f46d";
          }
        }
        &.recurring-profiles{
          &:before{
            content: "\f363";
          }
        }
        &.ps-config{
          &:before{
            content: "\f7d9";
          }
        }
        &.logout{
          &:before{
            content: "\f2f5";
          }
        }
      }

      &:hover, &:focus{
        color: $color-blue;
      }

      &--active{
        color: $color-blue;
      }
    }
  }
}

.sidebar__menu-list{
  .menu-list--item--has-dropdown{
    &:nth-child(2){
      @include break-max($mob){
        border-top: 1px solid $border-color;
      }
    }
  }
}




