.fieldset{
  &__row-mob-title{
    text-align: center;
    font-size: 18px;
    @include break-min($tab){
      display: none;
    }
  }
  &__row{
    display: inline-grid;
    width: 100%;
    grid-gap: 15px 40px;
    grid-template-columns: repeat(auto-fill, minmax($item-maxw, 1fr));
    @include break-max(2100px){
      grid-template-columns: repeat(auto-fill, minmax(355px, 1fr));
    }
    @include break-max(1920px){
      grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    }
    @include break-max(1700px){
      grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
    }
    @include break-max(1460px){
      grid-gap: 15px 30px;
      grid-template-columns: repeat(auto-fill, minmax(230px, 1fr));
    }
    @include break-max(1390px){
      grid-template-columns: repeat(auto-fill, minmax(390px, 1fr));
    }
    @include break-max($tab){
      //grid-gap: 0 30px;
    }
    @include break-max(930px){
      grid-template-columns: repeat(auto-fill, minmax(310px, 1fr));
    }
    @include break-max(480px){
      display: block;
      grid-template-columns: 1fr;
      //grid-gap: 0;

      > .form-group,
      > .checkbox-list,
      > .UserSelectList{
        margin-bottom: 16px;

        &__group{
          padding-top: 12px;
        }
      }
      > .checkbox-list{
        padding-top: 12px;
      }
    }

    > .checkbox-list{
      grid-column-start: 1;
      grid-column-end: 6;
      @include break-max(2550px){
        grid-column-end: 5;
      }
      @include break-max(1390px){
        grid-column-end: 3;
      }
      @include break-max(689px){
        grid-column-start: unset;
        grid-column-end: unset;
      }

      .form-group__group{
        .form-group__select-wrapper{
          display: inline-grid;
          width: 100%;
          grid-gap: 4px 40px;
          grid-template-columns: repeat(auto-fill, minmax($item-maxw, 1fr));
          @include break-max(2100px){
            grid-template-columns: repeat(auto-fill, minmax(355px, 1fr));
          }
          @include break-max(1920px){
            grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
          }
          @include break-max(1700px){
            grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
          }
          @include break-max(1460px){
            grid-gap: 4px 30px;
            grid-template-columns: repeat(auto-fill, minmax(230px, 1fr));
          }
          @include break-max(1390px){
            grid-template-columns: repeat(auto-fill, minmax(390px, 1fr));
          }
          @include break-max(930px){
            grid-template-columns: repeat(auto-fill, minmax(310px, 1fr));
          }
          @include break-max(480px){
            display: block;
            grid-template-columns: 1fr;
          }
        }
      }
    }

    .form-group{
      &__label{
        font-size: 16px;
      }
      &--checkbox{
        .form-check-label{
          font-size: 14px;
          margin-bottom: 0;
        }
      }
      &__group{
        .form-group__select-wrapper{
          .form-group{
            margin-bottom: 5px;
            &:last-child{
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }
  &__block{
    display: inline-block;
    width: 100%;

    &--checkbox-list{
      @include break-max(704px){
        padding-top: 20px;
      }
    }

    &-title{
      font-size: 16px;
      font-weight: $normal;
      margin-bottom: 10px;
    }

    .form-group{
      margin-bottom: 15px;

      &__label{
        color: $color-grey;

        &-icon{
          display: inline-block;
          min-width: 35px;
          width: 35px;
          text-align: center;
          font-size: 14px;
          color: $color-icons;
        }
      }

      &__input,
      &__select,
      &__textarea{
        .form-group__label-wrapper{
          //padding-left: 18px;
        }
      }
    }
  }
}