.hint-component{
  &__icon{
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    font-size: 14px;
    font-weight: 600;
    color: $color-icons;
    cursor: pointer;
  }

  &__block{
    position: fixed;
    top: auto;
    bottom: -25%;
    left: 0;
    width: 100%;
    font-family: $mainFont;
    font-weight: $normal;
    background: $white;
    box-shadow: 2px 2px 15px rgba(45, 51, 72, 0.1);
    z-index: 99;
    border-radius: 0;
    cursor: default;
    pointer-events: none;
    opacity: 0.5;
    visibility: hidden;
    @include transition;

    @include break-min($mob){
      position: absolute;
      top: 10px;
      bottom: auto;
      left: -100px;
      transform: scale(0.5);
      width: 200px;
      border-radius: 5px;
    }

    &:before{
      content: '';
      display: block;
      position: fixed;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 100%;
      background: rgba($color-dark, 0.5);
      z-index: -1;
    }

    &-show{
      pointer-events: auto;
      visibility: visible;
      opacity: 1;
      bottom: 0;

      &:before{
        display: block;
      }

      @include break-min($mob){
        top: 100%;
        transform: scale(1);
        &:before{
          display: none;
        }
      }
      @include transition;
    }
  }

  &__header{
    position: relative;
    background: $white;
    padding: 35px 20px 0 20px;
    border-top: 2px solid $color-green;
    z-index: 2;
    @include break-min($mob){
      border: none;
      padding: 12px 12px 0 12px;
    }
  }

  &__title{
    margin: 0;
    padding: 0;
    color: $color-dark;
    font-weight: $semibold;
    font-size: 18px;
    white-space: normal;
    @include break-min($mob){
      font-size: 15px;
    }
  }

  &__body{
    position: relative;
    background: $white;
    padding: 20px 20px 45px 20px;
    color: $color-dark;
    white-space: normal;
    line-height: 1.4;
    font-size: $main-text;
    z-index: 2;
    @include break-min($mob){
      line-height: 1.26;
      font-size: $small-text;
      padding: 12px;
    }
  }

  &:hover{
    .hint-component__block{
      @include break-min($mob){
        pointer-events: auto;
        visibility: visible;
        opacity: 1;
        top: 100%;
        transform: scale(1);
        @include transition;
      }
    }
  }
}