#root{
  min-height: 100vh;
  min-height: -webkit-fill-available;
}

.content-component{
  padding-left: 265px;
  padding-right: 40px;
  padding-top: 76px;
  padding-bottom: 20px;
  min-height: 100vh;
  transition: all 0.3s ease-out;
  min-height: -webkit-fill-available;

  @include break-max(1200px){
    padding-left: 40px;
  }

  @include break-max($tab){
    padding-top: 70px;
    padding-left: 20px;
    padding-right: 20px;
  }
}

.page{
  &__control-component{
    margin-bottom: 20px;
  }
}