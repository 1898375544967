.pagination{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin: 0 -2px;

  &__item{
    display: inline-block;
    margin: 2px;
    line-height: 1;
    color: $color-grey;

    a{
      display: flex;
      align-items: center;
      justify-content: center;
      line-height: 1;
      width: 36px;
      height: 36px;
      font-size: 14px;
      text-decoration: none;
      border-radius: 50%;
      color: $color-grey;
      @include transition;

      &:hover{
        background: rgba($secondary-brand-color, 0.7);
      }
    }

    .fal, i{
      font-size: 16px;
      font-weight: 300;
    }

    &--active{
      color: $color-dark;

      a{
        color: $color-dark;
        background: $secondary-brand-color;

        &:hover{
          background: darken($secondary-brand-color, 2%);
        }
      }
    }

    &--disabled {
      pointer-events: none;
      opacity: 0.35;
    }
  }
}

.pagination__wrapper {
  &.--has-item-count-select {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 10px 20px;
    @include break-max($mob) {
      flex-direction: column;
    }

    .pagination {
      flex: 1;
      @include break-min($mob) {
        justify-content: flex-start;
      }
    }
  }
}

.onpage-count {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row-reverse;
  margin-left: auto;
  margin-right: 0;

  .pull-left {
    white-space: nowrap;
    padding-right: 12px;
  }

  .form-control {
    width: 100px;
  }
}