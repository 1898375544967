.table{
  border-collapse: separate;
  border-spacing: 0 2px;

  &.table-striped{
    .table{
      &__header{
        .table__row{
          th,
          .table__header-item{
            border-bottom: none;
          }
        }
      }
    }
    tbody{
      tr{
        border-top: none;
        border-bottom: none;

        &:nth-of-type(even){

        }
        &:nth-of-type(odd){
          background: rgba($secondary-brand-color, 0.7);
          .table__cell-sticky{
            background: rgba($secondary-brand-color, 0.7);
          }
        }

        td{
          border: none !important;
        }
      }
    }

    &.no-hover{
      tbody{
        .table__row{
          &:hover{
            &:nth-of-type(odd){
              background: rgba($secondary-brand-color, 0.7) !important;
              .table__cell-sticky{
                background: rgba($secondary-brand-color, 0.7) !important;
              }
            }
          }
        }
      }
    }
  }

  &__wrapper{
    position: relative;
    margin-bottom: 20px;
  }

  &__scroller{
    margin-right: 74px;
    overflow-x: auto;
    overflow-y: visible;
    //width: calc(100% - 74px);
    width: calc(100% - 71px);
    min-height: 100px;
    padding: 4px;

    &--no-action{
      min-height: auto;
      width: 100%;
      margin-right: 0;
    }
  }

  thead,
  &__header{

    tr,
    &-row{

      th,
      .table__header-item{
        vertical-align: middle;
        font-size: 14px;
        color: $color-dark;
        font-weight: 500;
        border-top: none;
        height: 44px;
        padding: 0 16px;
        line-height: 1;
        border-bottom: 1px solid $secondary-brand-color;

        &.table__cell-sticky{
          height: 45px;
          display: flex;
          align-items: center;
        }
      }

      th:first-child{
        padding-left: 25px;
      }
    }
  }

  .table__header-item-recurring-info{
    color: transparent;
    font-size: 1px;
  }

  tbody,
  &__body{
    .table{
      tr,
      &__content-row{

        td,
        .table__cell{
          border-top: none;
          border-bottom: 1px solid $secondary-brand-color;
          white-space: nowrap;
          height: 44px;
          vertical-align: middle;
          padding: 2px 16px;
          font-size: 14px;
          line-height: 1.26;
          font-weight: 300;

          &.--text-danger{
            color: $color-red;
          }

          &-reason {
            cursor: pointer;
            max-width: 200px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            position: relative;
          }

          &-amount{
            color: $color-dark;
          }

          .tag{
            display: inline-flex;
            font-size: 12px;
            height: 24px;
            align-items: center;
            margin: 2px;
            padding: 4px 14px;
            border-radius: 4px;
            line-height: 1;
            font-style: italic;
            background: $secondary-brand-color;
            color: $color-grey;
            @include transition;

            &:first-child{
              margin-left: 0;
            }
            &:hover{
              background: darken($secondary-brand-color, 4%);
              color: $color-dark;
            }
          }

          &-name{
            white-space: normal !important;
          }
        }

        td:first-child{
          padding-left: 25px;
        }

        &:hover,
        &.selected{
          //z-index: 10;
          background: transparent !important;

          td{
            background: transparent !important;

            &:before, &:after{
              content: '';
              display: block;
              width: 100%;
              height: 5px;
              background: #fff;
              position: absolute;
              left: 0;
              top: 0;
              opacity: 0.99;
              @include break-max($mob){
                opacity: 0.5;
              }
            }

            &:after{
              top: auto;
              bottom: 0;
              box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.08);
            }
            &:before{
              box-shadow: 0px -4px 5px 0px rgba(0, 0, 0, 0.08);
            }
            @include break-max($mob){
              box-shadow: none;
              &:before, &:after{
                opacity: 0;
                visibility: hidden;
              }
            }

            &.table__cell-sticky{
              background: #fff !important;
              box-shadow: 10px 0px 20px -10px rgba(0, 0, 0, 0.08);
              @include break-max($mob){
                box-shadow: none;
                &:before, &:after{
                  opacity: 0;
                  visibility: hidden;
                }
              }
            }

            .actions-component__desk{
              @include break-min($mob){
                opacity: 1;
              }
            }
          }

          td:first-child{
            box-shadow: -6px 0px 10px -10px rgba(0, 0, 0, 0.20);
            @include break-max($mob){
              box-shadow: none;
            }
          }
        }
      }
    }
  }


  &__cell, td{
    position: relative;
    color: $color-grey;

    .cover-link{
      display: inline;
      line-height: 0;
      height: 0;
      width: 0;

      &:before{
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
      }
    }

    &-sticky{
      vertical-align: middle;
      white-space: normal;
      position: absolute !important;
      top: auto;
      right: 0;
      width: 75px;
      text-align: center;

      .actions-component{
        @include break-min($mob){
          margin-top: 3px;
        }
        &__toggler{
        }
      }
    }

    &-login,
    &.table__cell-login{
      color: $color-dark;
    }

    &-role{
      font-style: italic;

      .role{
        &--admin{
          color: $color-blue;
        }
        &--viewer{

        }
        &--accountant{

        }
      }
    }

    .status{
      position: relative;
      min-width: 80px;
      display: inline-flex;
      font-size: 12px;
      height: 24px;
      align-items: center;
      padding: 4px 14px;
      border-radius: 4px;
      line-height: 1;
      font-style: italic;
      @include break-max($mob){
        padding: 0;
        min-width: 26px;
        width: 26px;
        height: 26px;
        border-radius: 50%;
        overflow: hidden;
        font-size: 0;
        color: transparent;
      }

      &:before{
        display: inline-block;
        font-style: normal;
        font-variant: normal;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        font-family: "Font Awesome 5 Pro";
        font-weight: 300;
        margin-right: 6px;
        font-size: 12px;
        @include break-max($mob){
          position: absolute;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 100%;
          width: 100%;
          margin: 0;
          left: 0;
          top: 0;
          font-size: 16px;
        }
      }

      &__active,
      &__received{
        color: $color-green;
        @include break-min($mob){
          background: rgba($color-green, 0.1);
        }

        &:before{
          content: "\f00c";
        }
      }
      &__void,
      &__closed,
      &__canceled{
        color: $color-red;
        @include break-min($mob){
          background: rgba($color-red, 0.1);
        }

        &:before{
          content: "\f00d";
        }
      }
      &__error{
        color: $color-red;
        @include break-min($mob){
          background: rgba($color-red, 0.1);
        }

        &:before{
          content: "\f071";
        }
      }
      &__paid{
        color: darken($color-green, 4%);
        background: rgba($color-green, 0.16);

        &:before{
          content: "\f058";
        }
      }
      &__open,
      &__pending{
        color: $color-grey;
        background: rgba($secondary-brand-color, 0.8);

        &:before{
          content: "\f017";
        }
      }
      &__new{
        color: $color-blue;
        @include break-min($mob){
          background: rgba($color-blue, 0.1);
        }

        &:before{
          content: "\f067";
        }
      }
      &__http{
        color: $color-grey;
        background: rgba($secondary-brand-color, 0.8);
      }
      &__refunded,
      &__inactive{
        color: $color-grey;
        @include break-min($mob){
          background: rgba($secondary-brand-color, 0.8);
        }

        &:before{
          content: "\f122";
        }
      }
      &__inactive{
        &:before{
          content: "\f00d";
        }
      }
    }

    .table__cell-row{
      display: inline;
      @include break-max($mob){
        display: flex;
      }
      .table__cell-title{
        padding-right: 5px;
        display: none;
        @include break-max($mob){
          display: block;
        }
      }
      .table__cell-value{
        display: block;
        @include break-min($mob){
          display: inline;
        }
      }
    }
  }

  &__header-item-link{
    display: flex;
    align-items: center;
    height: 44px;
    color: $color-dark;
    text-decoration: none !important;

    .ico{
      margin-left: 10px;
    }

    &.asc,
    &.desc{
      color: $color-blue;
    }

    &.desc{
      .ico{
        &:before{
          content: '\f160';
        }
      }
    }

    &:hover{
      color: $color-blue;
    }
  }

  &.no-hover{
    tbody,
    .table__body{
      tr,
      .table__row{
        &:hover{
          box-shadow: none;

          td,
          .table__cell{
            box-shadow: none;

            &:before,&:after{
              display: none;
              box-shadow: none;
            }

            &:first-child,
            &:last-child{
              box-shadow: none;
            }
          }
        }
      }
    }
  }
}