@import "base/index";
@import "base/components/index";
@import "pages/index";
@import "~react-dates/lib/css/_datepicker.css";

// customize some Bootstrap variables
$primary: darken(#428bca, 20%);

.loading {
  top: 0;
  position: fixed;
  left: 0;
  height: 2px;
  background: $color-blue;
  animation: lineH 1s ease-in-out 0s infinite alternate;
  z-index: 10000;
  width: 10%;
}

@keyframes lineH{
  0%{
    left: 0;
  }
  100%{
    left: 90%;
  }
}
