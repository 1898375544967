.page__control-component{
  position: relative;
  padding-bottom: 10px;
  border-bottom: 1px solid $color-blue;
  @include break-max($tab){
    border-color: transparent;
    padding-bottom: 0;
    margin-bottom: 10px;
  }
  @include break-max($mob){
    border-bottom: none;
  }

  .btn-bar--back{
    display: none;
    margin-bottom: 10px;

    @include break-max($tab){
      display: inline-block;
    }

    .link--back{
      display: inline-flex;
      align-items: center;
      height: 30px;
      line-height: 1;
      color: $color-dark;
      text-decoration: none;
      @include transition;
      .ico{
        font-size: 16px;
        margin-right: 8px;
        color: $color-icons;

        &:before{
          content: '\f30a';
        }
      }

      &:hover{
        text-decoration: none;
        color: $color-blue;

        .ico{
          color: $color-blue;
        }
      }

      @include break-max($tab){
        .txt{
          display: none;
        }
        .ico{
          margin: 0;
          font-size: 18px;
          color: $color-dark;
        }
        padding: 0;
        height: 36px;
        width: 56px;
        border: 1px solid $border-color;
        border-radius: 50px;
        justify-content: center;
        text-align: center;
      }
    }

    &-visible{
      display: block;
      //margin-top: -10px;
    }
  }
}

.control-component{
  &__row{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0 -20px;

    &-item{
      margin: 5px 20px;

      &.title{
        @include break-max($tab){
          text-align: center;
          width: 100%;
        }
      }

      &.__export{
        .btn--export{
          @include break-max($tab){
            display: none;
          }
        }
      }
    }
  }
}

.filer-component{
  position: relative;
  margin-bottom: 20px;
  background: #fff;
  box-shadow: 2px 2px 15px rgba(45, 51, 72, 0.1);
  border-radius: 5px;
  padding: 0 30px;
  @include transition;

  &__mobile-bar{
    .btn{
      display: flex;
      width: auto;
      text-align: left;
      padding: 0 15px 0 0;
      background: transparent;
      outline: none;
      font-weight: bold;
      height: 34px;
    }

    &--row{
      display: flex;
      align-items: center;
      justify-content: space-between;

      .filter-count{
        margin-left: 20px;
        color: $color-grey;
      }

      @include break-min($tab){
        display: none;
      }
    }
  }

  &__header{
    position: relative;
    min-height: 60px;
    padding: 10px 50px 10px 0;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin: 0 -10px;

    .header-collapse-phantom-toggler{
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 90%;
      opacity: 0;
      z-index: 0;

      @include break-max($tab){
        display: none;
      }
    }

    &:after{
      content: '';
      display: block;
      width: calc(100% - 20px);
      height: 1px;
      background: $color-green;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      margin: 0 auto;
      opacity: 0.35;

      @include break-max($tab){
        display: none;
      }
    }

    &-item{
      margin: 4px 10px;

      &.filer-component__clear{
        margin-left: auto;
        opacity: 1;
        transform: translateY(0);
        visibility: visible;
        transition: all 0.25s ease;

        @include break-max($tab){
          padding-right: 0;
          background: transparent;
          border-color: transparent ;

          &:hover, &:active{
            background: transparent;
            border-color: transparent;
          }
        }
      }
      &.filer-component__close-popup{
        padding-left: 0;
        @include break-min($tab){
          display: none;
        }
        &:before{
          color: $color-icons;
        }
      }

      &.filer-component__select-status{
        padding-top: 4px;
        font-size: 12px;
        color: $color-grey;
      }
    }
  }

  &__show-filter{
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 1;
    padding: 0;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: $secondary-brand-color;
    border: none;
    @include transition;

    &:hover{
      background: darken($secondary-brand-color, 3%);
    }
    &:focus, &:active{
      background: darken($secondary-brand-color, 5%);
    }
  }

  &__form{
    padding-top: 30px;
    padding-bottom: 20px;

    .fieldset{
      &__block{
        .form-group{
          &__label{
            font-size: 16px;
            color: $color-dark;

            &.form-check-label{
              font-size: 16px;
            }

            &.fieldset__block-title{
              //font-size: 18px;
              font-size: 16px;
            }
          }
        }

        .checkbox-list{
          .form-group--checkbox{
            margin-bottom: 6px;
          }
        }
      }
    }
  }

  &__btn-bar{
    padding-top: 25px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-wrap: wrap;
    margin-left: -5px;
    margin-right: -5px;

    @include break-max($tab){
      padding-top: 0;
    }

    .btn{
      margin: 5px;

      &--green{
        @include break-max($tab){
          min-width: 180px;
        }
      }
    }
  }

  &__cover{
    @include break-min($tab){
      display: block !important;
      opacity: 1 !important;
      position: unset;
      top: 0;
      left: 0;
      z-index: unset;
      width: 100%;
      height: unset;
      overflow: unset;
      outline: 0;
    }
  }

  &__wrapper{
    max-width: 100%;
    width: 100%;
    margin: 0;
    background: #fff;
    transform: none;

    @include break-min($tab){
      position: unset;
      width: 100%;
      max-width: 100%;
      margin: 0;
      transform: unset !important;

      &.modal-dialog-scrollable{
        height: auto;
        max-height: unset;
      }
    }

    .modal-content{
      display: block;
      box-shadow: none;
      background: unset;
      border-radius: 0;
      border: none;
      @include break-min($tab){
        overflow: visible;
        height: auto !important;
        max-height: unset !important;
      }
    }
  }

  &__cover.show{
    overflow: hidden;

    .filer-component{
      @include break-max($tab){
        &__header{
          padding: 0 20px;
          margin: 0;
          justify-content: space-between;

          &-item{
            margin: 0;
          }
        }

        &__clear{

        }

        &__title,
        &__show-filter,
        &__select-status{
          display: none;
        }

        &__form{
          padding: 0;
        }
      }
    }

    .collapse__wrapper{
      @include break-max($tab){
        display: block !important;
        height: 100% !important;
        max-height: calc(100vh - 50px) !important;
        padding: 20px 20px 10px;
      }
    }

    .modal-content{
      height: 100%;
      padding-top: 50px;
      //padding-bottom: 50px;
      padding-bottom: 80px;

      .filer-component__btn-bar,
      .filer-component__header{
        position: absolute;
        left: 0;
        right: 0;
        height: 50px;
        min-height: 50px;
        overflow: hidden;
        background: #fff;
      }

      .filer-component__header{
        top: 0;
        border-bottom: 2px solid $color-green;
      }

      .filer-component__btn-bar{
        //height: 60px;
        //padding-bottom: 10px;
        height: 80px;
        padding-bottom: 25px;
        bottom: 0;
        justify-content: center;

        .filer-component__clear{
          display: none;
        }
      }

      .fieldset__scrollbox{
        height: 100%;
        max-height: calc(100vh - 200px);
        //max-height: calc(100vh - 164px);
        overflow-x: hidden;
        overflow-y: auto;
      }

      .fieldset__row{
        padding-bottom: 50px;
      }
    }
  }

  &.expanded{
    .filer-component__header{
      .filer-component__clear{
        opacity: 0.2;
        transform: translateY(10px);
        visibility: hidden;
      }
    }
  }

  &.expanded.__active-filter--on,
  &.__active-filter--on{
    .filer-component__mobile-bar{
      @include break-max($tab){
        border-bottom: 1px solid $color-green;
      }
      .filer-component__mobile-bar--row{
        .filter-count{
          color: $color-green;
        }
      }
    }
    .filer-component__clear{
      @include break-max($mob){
        opacity: 1;
        transform: translateY(0);
        visibility: visible;
      }
    }
  }
}

// second mobile view
.page__control-component{
  &--multiple{
    @include break-max($tab){
      position: relative;
      border-bottom: 1px solid $border-color;
      padding-bottom: 10px;
    }
    @include break-max($mob){
      border-bottom: none;
      padding-bottom: 0;
    }
    .btn-bar--back-visible{
      @include break-max($tab){
        position: absolute;
        margin: 0;
        max-width: 40%;
        display: inline-flex;
        align-items: center;
        height: 36px;
        top: 6px;
        left: 0;
      }
    }
    .control-component{
      &__row{
        &-item{
          &.actions{
            @include break-max($tab){
              min-height: 36px;
              order: -4;
              width: 100%;
              text-align: right;
              display: flex;
              justify-content: flex-end;
              padding-left: 60px;
              margin-bottom: 25px;

              .btn-bar{
                .btn{
                  margin-top: 0;
                  margin-bottom: 0;
                }
              }
            }
          }
        }
      }
    }
  }
}

.page{
  &__dashboard,
  &__users-list,
  &__organizations-list,
  &__customers-list{
    padding-top: 10px;

      .page__control-component{
        border-bottom: none;
        margin-bottom: 10px;
      }
  }
}