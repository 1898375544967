button, .btn {
  user-select: none;
}

button, .btn, a, .link {
  touch-action: manipulation;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  &:focus{
    outline: 0 !important;
  }
}

.link,
a {
  color: $color-blue;
  text-decoration: none;
}

a:active,
a:focus
,a:hover {
  text-decoration: underline;
  -webkit-tap-highlight-color: transparent;
}

.link{
  &:hover, &:focus{
    text-decoration: none;
  }
}

.btn {
  position: relative;
  overflow: hidden;
  font-size: 16px;
  min-height: 36px;
  line-height: 1;
  letter-spacing: 0.01em;
  font-family: $mainFont;
  font-weight: $normal;
  text-align: center;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 4px 25px;
  border-radius: 50px;
  outline: 0;
  border: none;
  cursor: pointer;
  text-decoration: none;
  transition: background 0.36s ease;
  -webkit-tap-highlight-color: transparent;

  @include break-min($tab){
    min-height: 30px;
    font-size: $main-text;
  }

  &--action{
    padding: 0;
    width: 36px;
    height: 36px;
    color: $color-grey;
    font-size: 16px;
    background: rgba($secondary-brand-color, 0.3);
    @include break-min($tab){
      width: 30px;
      height: 30px;
    }

    &.has-icon{
      &:before{
        margin: 0 !important;
      }
    }

    .btn--txt{
      display: none;
    }

    &:hover{
      background: $secondary-brand-color;
      color: $color-dark;
    }

    &-danger{
      color: $color-red;

      &:hover{
        color: darken($color-red, 12%);
      }
    }
  }

  &.has-icon{
    &:before{
      display: inline-block;
      font-style: normal;
      font-variant: normal;
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;
      font-family: "Font Awesome 5 Pro";
      font-weight: 300;
      margin-right: 8px;
    }

    &__add{
      &:before{
        content: '\f067';
      }
    }
    &__trash-alt{
      &:before{
        content: '\f2ed';
      }
    }
    &__long-arrow-alt-left{
      &:before{
        content: '\f30a';
      }
    }
    &__check{
      &:before{
        content: "\f00c";
      }
    }
    &__logout{
      &:before{
        content: "\f2f5";
      }
    }
    &__reply-all{
      &:before{
        content: "\f122";
      }
    }
    &__close{
      &:before{
        content: "\f00d";
      }
    }
    &__repeat-alt{
      &:before{
        content: "\f364";
      }
    }
    &__copy{
      &:before{
        content: "\f0c5";
      }
    }
    &__ban{
      &:before{
        content: "\f05e";
      }
    }
    &__exchange{
      &:before{
        content: "\f0ec";
      }
    }
    &__shield-check{
      &:before{
        content: "\f2f7";
      }
    }
    &__power{
      &:before{
        content: '\f011';
      }
    }
    &__export{
      &:before{
        content: '\f347';
      }
    }
    &__link{
      &:before{
        content: '\f0c1';
      }
    }
    &__config{
      &:before{
        content: '\f013';
      }
    }
    &__print{
      &:before{
        content: '\f02f';
      }
    }
    &__send,
    &__resend{
      &:before{
        content: '\f1d8';
      }
    }
    &__upload{
      &:before{
        content: '\f093';
      }
    }
  }

  &:focus,
  &:active {
    outline: 0;
    border: none;
    text-decoration: none !important;
  }
  &:hover{
    text-decoration: none !important;
  }

  .ico{
    line-height: 1;
    display: inline-block;
    margin-right: 8px;

    svg{
      height: 16px;
    }
  }

  &.btn--blue{
    @include gradient--blue;
    color: $white;
    background-size: 150%;
    background-position: left center;
    transition: all 0.6s ease;

    &:before{
      color: $white;
    }

    &:hover{
      color: $white;
      //@include gradient--blue-hover;
      background-position: right center;

      &:before{
        color: $white;
      }
    }
  }

  &.btn--green{
    @include gradient--green;
    color: $white;
    background-size: 160%;
    background-position: left center;
    transition: all 0.6s ease;

    &:before{
      color: $white;
    }

    &:hover{
      color: $white;
      background-position: right center;
      &:before{
        color: $white;
      }
    }
  }

  &.btn-link{
    padding-left: 0;
    padding-right: 0;
    min-height: 25px;
    background: transparent;
    font-weight: $normal;
    border-radius: 0;
    gap: 6px;

    &:hover, &:focus, &:active{
      text-decoration: underline;
    }
  }

  &.btn-link--white{
    color: $white;
  }

  &.btn-link--grey{
    color: $color-icons;
  }
  &.btn-link--primary{
    color: $color-blue;
  }

  &.btn--outline-cancel{
    background: transparent;
    border: 1px solid $border-color;
    color: $color-dark;

    .ico,
    &:before{
      color: $color-red;
    }
    &:hover{
      border: 1px solid $secondary-brand-color;
      background: $secondary-brand-color;
    }
  }

  &.btn--cancel{
    background: transparent;
    border: none;
    color: $color-dark;

    .ico,
    &:before{
      color: $color-red;
    }
  }

  &.btn--outline{
    background: transparent;
    border: 1px solid $border-color;
    color: $color-dark;

    .ico,
    &:before{
      color: $color-dark;
    }
    &:hover{
      border: 1px solid $secondary-brand-color;
      background: $secondary-brand-color;
    }
  }

  &.btn--grey{
    background: $secondary-brand-color;
    color: $color-dark;

    &:before{
      color: $color-icons;
    }

    &:hover{
      background: darken($secondary-brand-color, 4%);

      &:before{
        color: $color-dark;
      }
    }
  }

  &.__desk-view{
    @include break-max($tab){
      display: none;
    }
  }
}

.collapse__toggler{
  &-icon{
    transform: rotate(-180deg);
    @include transition;
  }

  &.collapsed{
    .collapse__toggler-icon{
      transform: rotate(0);
    }
  }
}