.nav-tabs{
  &__header{
    display: flex;
    align-items: stretch;
    padding: 0;
    margin: 0;
    border: none;

    &-item{
      display: inline-flex;
      height: 100%;
      align-items: center;
      min-height: 50px;
      border-bottom: 1px solid transparent;
      margin-bottom: -1px;
      padding: 5px 24px;
      line-height: 1.3;
      font-size: 14px;
      color: $color-grey;
      text-decoration: none !important;

      &:hover{
        color: $color-dark;
      }

      &.active{
        color: $color-dark;
        border-bottom: 2px solid $color-green;
      }
    }
  }
}

.tab-content{
  &__wrapper{
    padding: 20px 0;
  }
}