.alert{
  display: inline-flex;
  position: relative;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  font-size: 14px;
  line-height: 1.3;
  margin-bottom: 20px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  background: rgba($color-blue, 0.1 );
  font-style: italic;

  &:last-child{
    margin-bottom: 30px;
  }

  &:before{
    position: relative;
    display: inline-block !important;
    min-width: 16px;
    margin-right: 10px;
    text-align: center;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    font-family: "Font Awesome 5 Pro";
    font-weight: 300;
    font-size: 14px;
    color: $color-blue;
    top: -1px;
  }

  &-success{
    background: rgba($color-green, 0.1 );

    &:before{
      content: '\f00c';
      color: $color-green;
    }
  }

  &-danger{
    background: #ffeaee;//rgba($color-red, 0.1 );

    &:before{
      content: '\f071';
      color: $color-red;
    }
  }
}