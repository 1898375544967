.page__profile{
  max-width: 1500px;
  @include break-max($tab){
    padding-bottom: 50px;
  }

  .page__control-component{
    border-bottom: 1px solid $color-blue;
    padding-bottom: 10px;
    @include break-max($tab){
      border-bottom: none;
    }
    @include break-max($mob){
      padding-bottom: 0;
    }

    .control-component{
      &__row-item{
        &.actions{
          display: flex;
          align-items: center;

          .btn-bar--back{
            display: inline-block;
            margin: 5px;
          }
        }

      }
    }
  }

  &--form-content{
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;

    @include break-max($tab - 1){
      flex-direction: column-reverse;
      align-items: center;
      justify-content: center;
    }
  }

  &--form-fields{
    width: calc(100% - 420px);
    max-width: 400px;
    @include break-max($tab - 1){
      width: 100%;
    }

    &-title{
      font-size: 18px;
      padding-top: 15px;
      margin-bottom: 10px;
      font-weight: 400;

      @include break-max($tab){
        display: none;
      }
    }

    .form-group{
      margin-bottom: 12px;

      &__label-wrapper{
        padding-left: 18px;
      }
      &__label{
        color: $color-grey;
      }

      &--checkbox{
        .form-group__label{
          color: $color-dark;
        }
      }

      &:last-child{
        margin-bottom: 0;
      }
    }
  }
  &--securiry-settings{
    padding-top: 30px;
    @include break-max($tab){
      display: none;
    }
  }

  &--form-info-block{
    width: 400px;
    margin-left: 20px;
    background: $secondary-brand-color;
    border-color: 1px solid $border-color;
    border-radius: 5px;
    padding: 25px 35px;
    @include break-max($tab - 1){
      width: 100%;
      max-width: 400px;
      margin: 0 0 25px 0;
    }
    @include break-max($mob){
      padding: 25px 20px;
      width: calc(100% + 40px);
      margin-left: -20px;
      margin-right: -20px;
      border-radius: 0;

      .title--h3{
        display: none;
      }
    }

    .form-group{
      display: flex;
      align-items: center;
      margin-left: -5px;
      margin-right: -5px;

      &__label{
        margin: 0;
        color: $color-grey;
      }

      &__label-wrapper{
        width: calc(45% - 10px);
        margin: 0 5px;
        overflow: hidden;
      }
      &__input-wrapper{
        width: calc(55% - 10px);
        margin: 0 5px;

        .input{
          font-size: 14px;
          height: 31px;
          padding: 0;
          margin: 0;
          background: transparent !important;
          background-color: transparent !important;
          line-height: 1.5;
          border: none !important;
          color: $color-dark !important;
        }
      }

      &__data{
        .form-group__label-wrapper{
          height: 31px;
          align-items: center;
        }
      }
    }

    .profile-info__list{
      width: 100%;
      .table__cell{
        font-size: 14px;
        vertical-align: baseline;
        padding-top: 6px;
        padding-bottom: 6px;

        &-title{
          width: 120px;
        }
        &-value{
          color: $color-dark;
          white-space: normal;
          word-break: break-word;
        }
      }
    }
  }

  &--form-wrapper{
    > .control-component__row-item.actions{
      margin: 0;
      padding-top: 5px;
      .btn-bar{
        justify-content: center;
      }
    }
  }
}

.page__users-edit {
  .page__control-component{
    .btn-bar--back{

    }
  }
}

.page__users-edit {
  .page__control-component{
    display: flex;
    flex-wrap: nowrap;
    gap: 0 18px;
    @include break-min($tab){
      display: block;
    }
    .btn-bar--back{
      position: relative;
      top: 0;
    }
    .control-component__row{
      flex: 1;
      overflow: hidden;

      .title{
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  }
}

.organization-details,
.customer-details{
  --gap: 20px;
  --columns: 3;
  display: flex;
  max-width: 1400px;
  width: 100%;
  flex-wrap: wrap;
  gap: var(--gap);
  @include break-max($mob){
    flex-direction: column;
  }

  hr{
    height: 1px;
    background-color: $border-color;
    border: none;
  }

  &--item{
    padding: 22px;
    border-radius: var(--gap);
    background: rgba($secondary-brand-color, 0.5);
    min-width: 300px;
    width: calc((100% / var(--columns)) - var(--gap) + (var(--gap) / var(--columns)));
    @include break-max($tab){
      width: calc((100% / 2) - var(--gap) + (var(--gap) / 2));
    }
    @include break-max($mob){
      width: 100%;
      min-width: unset;
    }

    p{
      font-weight: $light;
      margin: 0;
      padding: 3px 0;
      display: flex;
      align-items: baseline;
      justify-content: flex-start;
      gap: 12px;
      color: $color-grey;

      .caption{
        flex: 1;
        display: flex;
        align-items: baseline;
        gap: 4px;
        &:after{
          content: ':';
          display: inline-block;
        }
      }
      .value{
        color: $color-dark;
      }
    }
  }
}