.modal-backdrop {
  @include gradient--dark-blue;
}

.modal {
  &.fade {
    .modal__dialog {
      transition: transform 0.3s ease-out;
      transform: translate(0, -50px);
    }
  }

  &.show {
    .modal__dialog {
      transform: none;
    }
  }

  &--red {
    .modal__close-panel,
    .modal__header {
      border-color: $color-red;
    }
  }

  &__dialog {
    position: relative;
    display: flex;
    flex-direction: column;
    max-width: 480px;
    margin: 2rem auto;
    padding: 25px 40px;
    border-radius: 5px;
    box-shadow: 2px 2px 15px rgba(45, 51, 72, 0.1);
    background-color: $white;
    background-clip: padding-box;
    pointer-events: auto;
    outline: 0;

    @include break-max($mob){
      padding: 60px 0 30px 0;
      border-radius: 0;
      margin: 0;
      max-width: 100%;
      width: 100%;
    }
  }

  &__close-panel,
  &__header {
    margin-bottom: 25px;
    border-bottom: 1px solid;
    border-color: $color-blue;

    &-close-btn--mob{
      @include break-min($tab){
        display: none;
      }
    }

    .close{
      position: relative;
      background: transparent;
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;
      margin: 0;
      line-height: 1;
      border: none;
      color: #fff;

      &:before, &:after{
        content: '';
        display: inline-block;
        position: absolute;
        background: $color-icons;
        height: 2px;
        width: 100%;
        max-width: 24px;
        border-radius: 3px;
        opacity: 1;
        top: 19px;
        left: 0;
        right: 0;
        margin: 0 auto;
      }

      &:before{
        transform: rotate(45deg);
      }
      &:after {
        transform: rotate(-45deg);
      }
    }
  }

  &.modal--confirm{
    .modal__header{
      margin-bottom: 15px;
    }
  }

  &__header{
    @include break-max($mob){
      border: none;
      padding: 14px 20px 0 20px;
      margin-bottom: 10px;
      text-align: center;
    }
  }

  &__close-panel{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 50px;
    padding: 0 20px;
    margin: 0;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    @include break-min($mob){
      display: none;
    }

    .btn--outline-cancel{
      padding-left: 0;
      border-color: transparent;
      background: transparent;

      &:before{
        color: $color-grey;
      }
      &:hover{
        border-color: transparent;
        background: transparent;
      }
    }
  }

  &__body {
    margin-bottom: 20px;
    @include break-max($mob){
      padding: 0 20px;
      margin-bottom: 20px;
      text-align: center;

      .form-group{
        text-align: left;
        margin-bottom: 14px;
        &:last-child{
          line-height: 1.22;
          margin-bottom: 0;
        }
      }
    }

    &-scroll-box{
      max-height: 60vh;
      overflow: auto;
      padding: 2px 1px;

      .form-group{
        margin-bottom: 14px;
        &:last-child{
          line-height: 1.22;
          margin-bottom: 0;
        }
      }
    }
  }

  &__footer {
    @include break-max($mob){
      padding: 0 20px;
    }
    &-row{
      align-items: center;
      display: flex;
      justify-content: flex-end;
      margin: 0 -5px;
      min-height: 40px;
      @include break-max($tab){
        height: 50px;
      }
      @include break-max($mob){
        justify-content: center;
      }
    }

    &-btn {
      margin: 0 5px;

      @include break-max($mob){
        min-width: 180px;
      }
    }

    .btn--close-popup{
      @include break-max($mob){
        display: none;
      }
      &:before{
        display: none;
      }
    }

    .btn-bar{
      @include break-max($mob){
        justify-content: center;
        .btn{
          min-width: 180px;
          &.btn--close-popup{
            display: none;
          }
        }
      }
    }
  }
}

.modal-dialog-scrollable {
  display: flex;
  height: 100%;
  max-height: calc(100% - 2px);

  .modal-content {
    height: 100%;
    max-height: calc(100vh - 2px);
  }
}