.period-switcher{
  &__list{
    display: flex;
    align-items: stretch;
    justify-content: center;
    margin-left: -5px;
    padding-right: -5px;
    @include break-min($lap){
      min-height: 38px;
    }

    .period-switcher{
      display: inline-flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      padding: 5px 22px;
      margin: 0;
      border-radius: 40px;
      line-height: 1.2;
      font-size: 14px;
      font-weight: $normal;
      color: $color-dark;
      background: #fff;
      cursor: pointer;
      white-space: nowrap;

      &__input{
        display: none;
        visibility: hidden;
      }
    }

  }
  &__list-item{
    //width: 20%;
    //max-width: 150px;
    //min-width: 100px;
    flex: 1;
    display: flex;
    align-items: stretch;
    margin: 2px 0;
    padding: 0 2px;

    &:hover{
      .period-switcher{
        background: rgba($secondary-brand-color, 0.4);
      }
    }

    &.active{
      .period-switcher{
        background: rgba($secondary-brand-color, 0.8);
      }
    }
  }
}